import { atom, useAtom } from "jotai";
import type {
  Activity,
  Chain,
  Collection,
  Comment,
  CurrentUser,
  SlappedSticker,
  StreamerRegistration,
  UserRole,
  Wallet,
} from "~/types";
import type { FlashSessionValuesSchema } from "~/util/sessions/flash";

export const currentUserAtom = atom<CurrentUser>(null);
export const isSubmittingAtom = atom<boolean>(false);
export const flashAtom = atom<FlashSessionValuesSchema>({
  toast: undefined,
});
export const dripPointsAtom = atom<number>(0);
export const availableSpinsAtom = atom<number>(0);
export const supportedChainsAtom = atom<Chain[]>([]);
export const userWalletsAtom = atom<Wallet[]>([]);
export const postSlappedStickerAtom = atom<Record<string, SlappedSticker>>({});
export const slappedStickerAtom = atom<SlappedSticker>(null);
export const activitiesAtom = atom<Activity[]>([]);
export const collectionsAtom = atom<Collection[]>([]);
export const isCollapseAtom = atom<boolean>(false);

export const commentsByPostIdAtom = atom<Record<string, Comment[]>>({});

export const userRolesAtom = atom<UserRole[]>([]);
export const userRegistrationsAtom = atom<StreamerRegistration[]>([]);
export const showLatestUpdateBarAtom = atom<boolean>(false);

export { useAtom };
